.focuspoint {
		height: $focuspoint-height-small;
		background: #fafafa;
}

@media(min-width: $screen-xs) {
	.focuspoint {
		&.focuspoint__large,
		&.focuspoint__maxi {
			height: $focuspoint-height-medium;
		}
	}
}

@media(min-width:$screen-sm)
{
	.focuspoint {
		&.focuspoint__tiny{
			height: $focuspoint-height-tiny;
		}
		&.focuspoint__maxi {
			height: $focuspoint-height-maxi;
		}
		&.focuspoint__large {
			height: $focuspoint-height-large;
		}
		&.focuspoint__medium {
			height: $focuspoint-height-medium;
		}
	}
}
	