body{
  .navigation-open {
    overflow: hidden;
    .header-container {
      background: #fff;
      transition: background .6s;
    }
  }
}
.navbar-inverse {
  background-color: $brand-secondary;
  border-color: $brand-secondary;
  .navbar-brand {
    color: $body-bg;
  }
  .navbar-nav {
    & > li {
      & > a {
        color: $body-bg;
      }
    }
  }
}

.navbar-toggle{
  .icon-bar {
    background-color: $brand-secondary;
    position: relative;
  }
}

.navigation {
  position: fixed;
  overflow: scroll;
  width: 100%;
  background: #fff;
  height: 100% !important;
  max-height: none !important;
  z-index: 5;
  top:0;
  left:0;

  opacity: 0;
  pointer-events: none;

  transition: opacity .5s .1s;

  .navigation-fullpage-header{
    padding-top: 20px;
    text-align: center;
    .navbar-toggle{
      position: absolute;
      top:0;
      right: 0;
      color: $brand-secondary;
      margin-top: 0;
    }
    .navigation-fullpage-logo-image{
      height: 100px;
      margin-top: 3px;
    }
  }
  .navigation-list {
    margin-top: 25px;
    padding:0 $padding-small-vertical;

    text-align: center;
  }
  li {
    padding: $padding-base-vertical 0;
    list-style: none;
  }
  .navigation-current li {
    opacity: 0;
    transform: translate(-10%,0);
    transition: all .3s;
    border-top: 1px solid $gray;
    &:nth-child(2) {
      transition-delay: .05s;
    }
    &:nth-child(3) {
      transition-delay: .1s;
    }
    &:nth-child(4) {
      transition-delay: .15s;
    }
    &:nth-child(5) {
      transition-delay: .2s;
    }
    &:nth-child(6) {
      transition-delay: .25s;
    }
    &:nth-child(7) {
      transition-delay: .3s;
    }
    &:last-child{
      border-bottom: 1px solid $gray;
    }
    &.backlink-li{
      border-top: none;
    }
  }
  a {

    font-weight: normal;
    font-size: $font-size-h1;
    line-height: 1;
    padding: 0 $padding-base-horizontal;
    position: relative;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    color: #9e9e9e;
  }
  .dropdown-toggle {
    display: none;
  }

  .logo {
    position: absolute;
    margin-left: $padding-small-horizontal;
  }
  .logo, .navbar-toggle {
    display: block;
    top: $padding-small-vertical;
  }

  &.navigation-open {
    opacity: 1;
    pointer-events: all;
    transition: opacity .6s;
  }

  &.navigation-full-page {
    .navigation-list {
      width: 100%;
      position: absolute;
      top: 100px;
      left:0;
    }
    .main-list {
      display: none;
    }
    .shadow-list {
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
    }
    .navigation-previous {
      li {
        transition: none;
        opacity: 0;
      }
      li {
        transform: translate(-10%,0);
        opacity: 0;
      }
      li:nth-child(2) {
        transition-delay: .05s;
      }
      li:nth-child(3) {
        transition-delay: .1s;
      }
      li:nth-child(4) {
        transition-delay: .15s;
      }
      li:nth-child(5) {
        transition-delay: .2s;
      }
      li:nth-child(6) {
        transition-delay: .25s;
      }
      li:nth-child(7) {
        transition-delay: .3s;
      }
    }
  }

  &.navigation-open.navigation-full-page {
    .navigation-current {
      opacity: 1;
      pointer-events: all;
      li {
        transition: all .3s;
        opacity: 1;
        transform: translate(0, 0);
      }
      li:nth-child(2) {
        transition-delay: .05s;
      }
      li:nth-child(3) {
        transition-delay: .1s;
      }
      li:nth-child(4) {
        transition-delay: .15s;
      }
      li:nth-child(5) {
        transition-delay: .2s;
      }
      li:nth-child(6) {
        transition-delay: .25s;
      }
      li:nth-child(7) {
        transition-delay: .3s;
      }
    }
  }
  .navigation-backlink {
    color: $brand-secondary;
    font-size: $font-size-base;
    position: absolute;
    top:-115px;
    left: 0;
    &:hover {
      &:after {
        width: 0;
      }
    }
  }
}

.ie .navigation {
  display: none;
  opacity: 1;
  &.navigation-open {
    display: block;
  }
}

.header{
  .header-logo{
    display: none;
  }
  .header-cta{
    display: none;
  }
}
/** BEGIN: Fullpage enabled Styles */
.fp-enabled{
  .header{
    .header-logo{
      display: block;
    }
    .header-cta{
      display: block;
    }
  }
}
/** END: Fullpage enabled Styles */
@media(min-width: $screen-xs){}

@media(min-width: $screen-sm)
{
  .navbar-header{
    float: none;
  }
  .navbar-toggle{
    display: block;
  }
}

@media(min-width: $screen-md)
{
  .header{
    .header-logo{
      display: block;
    }
    .header-cta{
      display: block;
    }
  }
  .navbar-fixed-top {
    min-height: 0;
    .navbar-header{
      display: none;
      .navbar-toggle {
        display: none;
      }
    }
  }
  .navigation{
    .navigation-fullpage-header{
      padding-top: 0;
      .navigation-fullpage-logo-image{
        height: 124px;
        margin-top: 0;
        margin-left: 14px;
      }
    }
  }
}

@media(min-width: $screen-lg){}