$pictureOffset: 15%;
/** BEGIN: Fullpage enabled Styles */
.fp-enabled{
  .show-only-if-fp-is-disabled{
    display: none;
  }
  .news-item {
    position: relative;
    height: 100vh;
    .interferer {
      position: absolute;
      display: none;
      left: -70px;
      top: -20px;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background-color: $brand-secondary;
      box-shadow: -1px -2px 3px 2px rgba(0,0,0,0.1);
      z-index: 10;
      .interferer-content {
        position: absolute;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transform-origin: bottom right;
        color: #fff;
        width: 70px;
        top: 60px;
        left: 60px;
        text-align: center;
      }
    }
    &.news-special{
      background-color: $brand-primary;
      .news-content{
        width: 100% + $pictureOffset;
        padding-right: $pictureOffset;
        background: -moz-linear-gradient(top, rgba(119,184,0,0) 0%, rgba(119,184,0,1) 70%, rgba(119,184,0,1) 100%);
        background: -webkit-linear-gradient(top, rgba(119,184,0,0) 0%,rgba(119,184,0,1) 70%,rgba(119,184,0,1) 100%);
        background: linear-gradient(to bottom, rgba(119,184,0,0) 0%,rgba(119,184,0,1) 70%,rgba(119,184,0,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0077b800', endColorstr='#77b800',GradientType=0 );
        .news-next-link{
          color:white;
        }
      }
      .interferer {
        display: block;
        background-color: $brand-primary;
        .interferer-content-is-special {
          transform-origin: top left;
          width: 32px;
          top: 96px;
          left: 72px;
          display: inline;
        }
        .interferer-content-is-new{
          display: none;
        }
      }
      .fullpageslide-news-content{
        .news-special-marker{
          font-size: $font-size-h3;
          color: $text-color;
          margin: 0;
          a {
            color: $text-color;;
          }
        }
        h2{
          color: white;
        }
      }
    }
    &.is-new{
      .interferer{
        display: block;
        .interferer-content-is-new{
          display: inline;
        }
        .interferer-content-is-special{
          display: none;
        }
      }
      &.news-special{
        .interferer{
          background-color: $brand-secondary;
        }
      }
    }
    .fullpage-slide{
      position: relative;
      width: 100%;
      height: 100%;
    }
    .fp-controlArrow{
      border: none;
      width: 32px;
      height: 32px;
      color: white;
      margin-top: 0;
    }

    .news-image {
      position: relative;
      width: 100% + $pictureOffset;
      height: 100%;
      .focuspoint {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .news-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $padding-base-vertical*4 $padding-base-horizontal*1.5 0;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.8+70 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 70%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 70%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 70%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
    }
    .news-date {
      font-size: $font-size-h3;
      color: $body-bg;
      margin: 0;
    }
    .news-headline {
      font-size: $font-size-h2;
      color: $body-bg;
      margin: $padding-base-vertical*0.5 0 $padding-base-vertical;
      a {
        color: $body-bg;
      }
    }
    .news-text{
      padding-bottom: 100px;
    }
    .news-slide-navigation{
      color: white;
      position: absolute;
      display: none;
      &.news-next-slide-link{
        right: $padding-base-horizontal;
        top:50%;
      }
      &.news-prev-slide-link{
        left: $padding-base-horizontal;
        top:50%;
      }
    }
    .news-next-link-con {
      //margin-left: $padding-base-horizontal*1.5;
      text-align: center;
      svg {
        width: $font-size-base;
        height: $font-size-base;
        position: relative;
        top: 3px;
      }
    }
    &:last-child{
      .news-next-link-con{
        display: none;
      }
    }

    .fullpageslide-news-teaser{

    }
    .fullpageslide-news-content{
      //padding-top: 50px;
      //padding-bottom: 100px;

      h2{
        color: $text-color;
      }
      .inner-content{
        margin-left: $pictureOffset;
        padding: 0 $padding-base-horizontal;
        padding-top: 50px;

        ul{
          li{
            list-style: none;
            padding-left: 20px;
          }
        }
      }
    }
  }
}
.fp-disabled{
  .show-only-if-fp-is-disabled{
    display: block;
    .row {
      position: relative;
      min-height: 250px;
      .news-item-image-container {
        position: absolute;
        height: 100%;
      }
      .news-item-content-container {
        &.col-md-4 {
          margin-left: 58.33333%;
        }
        &.col-md-6 {
          margin-left: 41.66667%;
        }
        &.col-md-7 {
          margin-left: 33.33333%;
        }
        &.col-md-8 {
          margin-left: 25%;
        }
      }
    }
  }
  .fullpage-slide{
    display: none;
  }
  .news-item{
    .interferer {
      position: absolute;
      display: none;
      left: 0;
      top: 0;
      width: 70px;
      height: 70px;
      border-radius: 0 0 140px 0;
      -moz-border-radius: 0 0 140px 0;
      -webkit-border-radius: 0 0 140px 0;
      background-color: $brand-secondary;
      box-shadow: -1px -2px 3px 2px rgba(0,0,0,0.1);
      z-index: 10;
      .interferer-content {
        position: absolute;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        transform-origin: center center;
        color: #fff;
        width: 70px;
        top: 17px;
        left: -5px;
        text-align: center;
      }
    }

    &.is-new{
      .interferer{
        display: block;
        .interferer-content-is-new{
          display: inline;
        }
        .interferer-content-is-special{
          display: none;
        }
      }
      &.news-special{
        .interferer{
          background-color: $brand-secondary;
        }
      }
    }
    background-color: white;
    margin-bottom: $padding-base-vertical*2;
    position: relative;
    .news-content{
      padding-top: $padding-base-vertical*2;
      overflow: auto;
      .news-date{
        color: $brand-secondary;
        font-size: 16px;
        font-family: "Trebuchet MS","bdkj_regular", Tahoma, Verdana, Arial, sans-serif;
        margin-bottom: $padding-base-vertical;

      }
      .news-headline{
        margin-top: 0;
        a{
          color: black;
        }
      }
      .news-teaser{
        font-size: 18px;
      }
    }
    .news-item-image {
      min-height: 250px;
      background-position: center center;
      background-size: cover;
      height: 100%;
      transition: height linear .4s;
    }
    .news-more-block{
      display: none;
      overflow: auto;
    }
    .news-next-link,.news-slide-navigation{
      display: none;
    }
    .sg-news-more-trigger{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 70px;
      height: 70px;
      box-shadow: -1px -2px 3px 2px rgba(0,0,0,0.1);
      z-index: 10;
      background: $brand-primary;
      border-radius: 140px 0 0 0;
      -moz-border-radius: 140px 0 0 0;
      -webkit-border-radius: 140px 0 0 0;

      .sg-news-more-trigger-content {
        position: absolute;
        color: #fff;
        width: 32px;
        left: 25px;
        top: 25px;
        text-align: center;
        cursor: pointer;
        transition: all .5s;
      }
      
      &.open {
        .sg-news-more-trigger-content {
          transform: rotate(-90deg);
        }
      }
    }

    &.news-special{
      background: $brand-primary url('../../assets/images/news-special-background.gif') no-repeat top right;
      background-size: 100% 100%;
      a{
        color: white;
        &:hover{
          color: white;
          text-decoration: underline;
        }
      }
      .fullpageslide-news-content{
        .news-special-marker{
          color: white;
        }
        h2{
          color: white;
        }
        .inner-content{
          color: white;

        }
      }
      &, .news-date{
        color: white;
      }
      .interferer {
        display: block;
        background-color: $brand-primary;
        .interferer-content-is-special {
          transform-origin: center center;
          width: 32px;
          left:17px;
          display: inline;
        }
        .interferer-content-is-new{
          display: none;
        }
      }
    }
 
    &.aus-dem-bdkj {
      .news-date {
        color: $brand-primary;
      }
    }
    &.aus-dem-bistum {
      .news-date {
        color: $brand-option;
      }
      .sg-news-more-trigger {
        background-color: $brand-option;
      }
    }
    &.aus-den-verbaenden {
      .sg-news-more-trigger {
        background-color: $brand-secondary;
      }
    }
  }
}

.news-list-view{
  h2 {
    margin-top: $padding-base-vertical*5;
    margin-bottom: $padding-base-vertical*3;
  }
  .news-list-item{
    margin-bottom: $padding-base-vertical*2;
    h3{
      margin-top: 0;
      color: $brand-primary;
      font-size: 28px;
    }
    hr{
      border-color: $gray;
    }
  }
  .page-navigation{
    text-align: right;
    ul,li{
      list-style: none;
      margin: 0;
      padding: 0;
      text-indent: 0;
    }
    ul{
      li{
        display: inline-block;

      }
    }
  }
}
.news-date-menu{
  &,li{
    list-style: none;
    margin-left: 0 !important;
    padding: 0;
    text-indent: 0 !important;
    line-height: 16px;
    font-size: 16px;

  }
  li{
    .icon{
      color: $brand-primary;
      width: 14px;
      height: 14px;
    }
    margin-bottom: $padding-base-vertical;
  }
}
.fp-enabled{
  .news-item.news-special{
    .fullpageslide-news-content{
      h2{
        color: white;
      }
      .news-text{
        color: white;
      }
      a{
        color: white;
        text-decoration:underline;
        &:hover{
          color:white
        }
      }
      .news-special-marker{
        color: white;
      }
    }
  }
}