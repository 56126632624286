.header {
  position: absolute;
  z-index: 1000;
  width: 100%;
  top: 0;
  transition: top .7s;
  &.header__hidden {
    top: -164px;
  }
}
.header-top {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: $brand-secondary;
  font-size: $font-size-small;
  text-align: center;
  ul {
    margin: 0;
  }
  a {
    color: $body-bg;
    text-decoration: none;
  }
  svg {
    position: relative;
    top: 2px;
    height: 16px;
    width: 16px;
    margin-right: $padding-base-horizontal*0.5;
  }
}
.header-logo {
  background-color: $body-bg;
  p {
    margin: 0;
  }
  .header-logo-image {
    height: 100px;
    transition: height .3s;
  }
  .container {
    position: relative;
  }
  .header-logo-paper-plane,
  svg {
    height: $padding-base-vertical*2.5;
    width: $padding-base-horizontal*2.5;
  }
  .header-logo-paper-plane {
    position: absolute;
    top: $padding-base-vertical*0.5;
    right: $padding-base-horizontal;
  }
  .header-socials{
    position: absolute;
    top:20px;
    right: 30px;
  }
}
.header-cta {
  position: absolute;
  width: 100%;
  bottom: -36px;
  text-align: center;
  z-index: 1000;
  ul {
    margin: 0;
    display: inline-block;
  }
  li {
    list-style: none;
    float: left;
  }
  a {
    padding: $padding-base-vertical*0.5 $padding-base-horizontal*2;
    background-color: $brand-primary;
    color: $body-bg;
    font-size: $font-size-h3;
  }
}
.header-main {
  .navbar-default {
    transition: top .3s;
  }
}

.wrapper__homepage {
  .header-main {
    .navbar-default {
      top: -100%;
    }
  }
  .header__hidden {
    .header-main {
      .navbar-default {
        top: 0;
      }
    }
  }
  .main-content {
    .main-content-inner {
      margin-top: $padding-base-vertical*2;
    }
  }
}

@media( min-width: $screen-sm )
{
  .header-logo {
    .header-logo-image {
      height: 124px;
    }
    .header-logo-paper-plane {
      display: none;
    }
  }
}