body {
  background-color: $gray-light;
}

.flat-overview {
	margin: $padding-large-vertical 0;
	img {
		max-width: 100%;
	}
}

.no-desktop {
	@media(min-width: $screen-md-min) {
		display: none;
	}
}
.no-mobile {
	@media(max-width: $screen-sm-max) {
		display: none;
	}
}

.content-header {
	height: 50px;
	background-color: rgb(255, 255, 255);
}

.main-content {
	padding-left: 0;
	padding-right: 0;
	.main-content-inner{
		margin-top: 51px;
	}
	ul,ol{
		margin-left: 0.6em;
		text-indent: 0.2em;
	}

	.section{
		background-color: white;
		padding: 20px 20px 20px 40px;
		overflow: auto;
		margin-bottom: $padding-base-vertical;
		position: relative;
		&.with-interferer{
			padding-top: 100px;
		}
		.interferer {
			position: absolute;
			left: 0;
			top: 0;
			width: 100px;
			height: 100px;
			border-radius: 0 0 200px 0;
			-moz-border-radius: 0 0 200px 0;
			-webkit-border-radius: 0 0 200px 0;
			box-shadow: -1px -2px 3px 2px rgba(0,0,0,0.1);
			z-index: 10;
			&.interferer-primary{
				background-color: $brand-primary;
			}
			&.interferer-secondary{
				background-color: $brand-secondary;
			}
			.interferer-content {
				position: absolute;
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-ms-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
				transform-origin: center center;
				color: #fff;
				width: 100px;
				top: 33px;
				left: -7px;
				text-align: center;
			}
		}
	}
}

.footer-divider{
	hr{
		border-color: $gray;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
.footer {
	.container{
		margin-top: $padding-base-vertical;
		padding-bottom: $padding-base-vertical*4;
		text-align: center;
	}
	.footer-logos{
		padding: $padding-base-vertical 0;
		background-color: white;
		.footer-logo-item{
			display: inline-block;
			padding: 0 $padding-base-horizontal/2;
		}
	}
	.footer-navigation{
		margin-top: $padding-base-vertical;
		ul,li{
			list-style: none;
			padding: 0;
			margin: 0;
		}
		ul{
			li{
				padding: 0 $padding-base-horizontal;
				display: inline-block;
			}
		}
	}
}
.container-relative{
	position: relative;
}

table.table-outline{
	border: 1px solid #ddd;
	tbody, thead{
		th,td{
			border: none;
		}
	}
}

@media(min-width: $screen-md-min) {

	.main-content {
		z-index: 10;
		background:none;
		.main-content-inner{
			margin-top: 124px;
		}
	}
	.fp-tableCell {
		vertical-align: top;
	}
}