/**
 * Default overrides to flatten slider
 */
.bx-wrapper {
  margin-bottom: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: none;
  .bx-content-con {
    
  }
  .bx-prev,
  .bx-next {
    background-image: url(../../assets/images/controls.png);
  }
  .bx-loading {
    background-image: url(../../assets/images/bx_loader.gif);
  }
}