$headerHeight: 124px;
.homepage-special {
  height: 100vh;
  //padding-top: $headerHeight;
  //margin-top: - $headerHeight;
  position: relative;
  overflow: hidden;
  .container {
    height: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .focuspoint {
    height: 100%;
  }
  .homepage-special-slogan {
    position: absolute;
    top: 50%;
    left: 0;
    font-size: $font-size-h4;
    .homepage-special-slogan-item {
      span {
        background-color: $body-bg;
        padding: $padding-base-vertical*0.3 $padding-base-horizontal;
        margin: $padding-base-horizontal*0.5 0 0 0;
        display: inline-block;
        box-shadow: 1px 2px 3px 1px rgba(0,0,0,0.2);
      }
    }
  }
  .interferer {
    position: absolute;
    display: block;
    bottom: -70px;
    right: -70px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: $brand-secondary;
    box-shadow: -1px -2px 3px 2px rgba(0,0,0,0.1);
    .interferer-content {
      position: absolute;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform-origin: top left;
      color: #fff;
      width: 85px;
      top: 69px;
      left: 5px;
      text-align: center;
    }
  }
  .news-link {
    position: absolute;
    bottom: $padding-base-vertical;
    left: 50%;
    width: 48px;
    height: 48px;
    display: block;
    margin-left: -24px;
    color: $body-bg;
    svg {
      width: 48px;
      height: 48px;
      text-shadow: 0 0 2px 2px rgba(0,0,0,0.5);
    }
    .badge {
      display: none;
      position: absolute;
      top: 0;
      right: -3px;
      background-color: $brand-secondary;
      color: $body-bg;
    }
  }
}

@media( min-width: $screen-xs )
{
  .homepage-special {
    .container {
      position: relative;
      padding-left: $padding-base-horizontal*1.5;
      padding-right: $padding-base-horizontal*1.5;
      overflow: hidden;
    }
    .homepage-special-slogan {
      font-size: $font-size-h2;
      left: $padding-base-horizontal*1.5;
    }
    .interferer {
      right: $padding-base-horizontal*6.5;
      top: $headerHeight + $padding-base-vertical*5;
      width: 100px;
      height: 100px;
      .interferer-content {
        width: 100%;
        top: 50%;
        left: $padding-base-horizontal*0.5;
        padding: 0 $padding-base-horizontal;
        transform-origin: center center;
        -webkit-transform: rotate(-15deg) translateY(-50%);
        -moz-transform: rotate(-15deg) translateY(-50%);
        -ms-transform: rotate(-15deg) translateY(-50%);
        -o-transform: rotate(-15deg) translateY(-50%);
        transform: rotate(-15deg) translateY(-50%);
      }
    }
  }
}
