b, strong {
  font-family: $font-family-sans-serif-bold;
  font-weight: 400;
}

.navbar-toggle {
  margin-right: $padding-base-horizontal*0.5;
  margin-bottom: 0;
  margin-top: $padding-base-vertical*0.5;

  .icon-bar {
    width: $padding-base-horizontal*2.5;
    height: 4px;
  }
}

.navbar-default {
  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;
    img {
      max-height: 100%;
    }
  }
  .navbar-nav {
    font-size: $font-size-h4;
    & > li {
      & > a {
        padding-right: $padding-base-horizontal*4.5;
      }
    }
  }
  .dropdown-toggle {
    color: $brand-primary;
    position: absolute;
    right: 0;
    top: 0;
    padding: $padding-base-vertical $padding-base-horizontal*1.5 $padding-base-vertical $padding-base-horizontal;
    .glyphicon-minus {
      display: none;
    }
  }
  .open .dropdown-toggle {
    .glyphicon-minus {
      display: block;
    }
    .glyphicon-plus {
      display: none;
    }
  }
}

.navbar-collapse {
  @media (min-width: $grid-float-breakpoint) {
    &.collapse {
      height: 100% !important;
      overflow-y: scroll !important;
    }
  }
}