.card {
	overflow: auto;
	margin-bottom: $padding-large-vertical;
	margin-top: $padding-large-vertical;
	.card-image {

	}
	.card-title {
		
	}
	.card-content {
		padding: 0 $padding-base-horizontal;
		overflow: auto;
	}
	.card-cta {
		margin-bottom: $padding-base-vertical;
		padding: 0 $padding-base-horizontal;
	}

	// Alternatives
	&.card__default {

	}
	&.card__primary {

	}
	&.card__secondary {

	}
}