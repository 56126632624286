.chromeframe {
  padding: 10px 40px;
  background: $brand-danger;
  text-align: center;
  font-size: 18px;
  color: white;
  a {
    color: white;
    text-decoration: underline;
  }
}