@font-face {
  font-family: 'bdkj_bold';
  src: url('../../assets/webfonts/bdkj-bold-webfont.eot');
  src: url('../../assets/webfonts/bdkj-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/webfonts/bdkj-bold-webfont.woff2') format('woff2'),
  url('../../assets/webfonts/bdkj-bold-webfont.woff') format('woff'),
  url('../../assets/webfonts/bdkj-bold-webfont.ttf') format('truetype'),
  url('../../assets/webfonts/bdkj-bold-webfont.svg#bdkjbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bdkj_light';
  src: url('../../assets/webfonts/bdkj-light-webfont.eot');
  src: url('../../assets/webfonts/bdkj-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/webfonts/bdkj-light-webfont.woff2') format('woff2'),
  url('../../assets/webfonts/bdkj-light-webfont.woff') format('woff'),
  url('../../assets/webfonts/bdkj-light-webfont.ttf') format('truetype'),
  url('../../assets/webfonts/bdkj-light-webfont.svg#bdkjlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bdkj_regular';
  src: url('../../assets/webfonts/bdkj-regular-webfont.eot');
  src: url('../../assets/webfonts/bdkj-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../assets/webfonts/bdkj-regular-webfont.woff2') format('woff2'),
  url('../../assets/webfonts/bdkj-regular-webfont.woff') format('woff'),
  url('../../assets/webfonts/bdkj-regular-webfont.ttf') format('truetype'),
  url('../../assets/webfonts/bdkj-regular-webfont.svg#bdkj_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;
}