$icon-font-path:            "../../assets/fonts/";
$assets-folder:             "../../assets/";

$body-bg:                   #fff;
$brand-primary:             #77B800;
$brand-secondary:           #1BBBE9;
$brand-option:              #D30069;
$gray:                      #9E9E9E;
$gray-light:                #e7e7e7;
$text-color:                #000;
$link-color:                $brand-secondary;

$font-family-sans-serif:          "bdkj_regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif-bold:     "bdkj_bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif-light:    "bdkj_light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-small:           12px;
$font-size-base:            16px;
$font-size-h1:              28px;
$font-size-h2:              28px;
$font-size-h3:              23px;
$font-size-h4:              21px;
$font-size-maxi:            48px;

$padding-base-vertical:     10px;
$padding-base-horizontal:   10px;

$headings-font-family:      $font-family-sans-serif;
$headings-font-weight:      400;

$navbar-default-bg:                         $body-bg;
$navbar-default-border:                     $body-bg;
$navbar-default-color:                      $brand-primary;
$navbar-default-link-color:                 $brand-primary;
$navbar-default-link-hover-color:           $brand-primary;
$navbar-default-link-active-color:          $brand-primary;
$navbar-default-link-active-bg:             transparent;

$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        $brand-secondary;
$navbar-default-toggle-border-color:       transparent;