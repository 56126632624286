/**
 * Global SCSS file.
 * 
 * Only include SCSS modules here.
 * The list below may contain more modules than you will need. 
 * Just remove or comment the entries you do not need.
 */


@import "src/mixin_additional";
@import "src/module-font";
// BEGIN Variables Definition
@import "src/jquery-focuspoint-variables";
// so we can also override module variables in src/variables
@import "src/variables";
// END Variables Definition

/**
 * Bootstrap
 */
// @import "src/bower";
@import "src/bootstrap-include";
@import "src/bootstrap-overrides";

/**
 * Fluid Content Elements
 */
@import "src/fluidelements";

/**
 * Focuspoint
 */
@import "src/jquery-focuspoint";
@import "src/jquery-focuspoint-overrides";

/**
 * BX-Slider 4
 */
@import "src/bxslider";
@import "src/bxslider-overrides";

@import "src/fullpage";

/**
 * Own Modules
 */
@import "src/own-modules";

/**
 * Own Module Files
 */
@import "src/module-update-browser-hint";
@import "src/module-fontastic";
@import "src/module-header";
@import "src/module-navigation";
@import "src/module-card";
@import "src/module-homepage-special";
@import "src/module-fullpage-news";
//@import "src/module-sg-fullpagemenu";
@import "src/module-sg-magazine-blockquote";

