.magazine-blockquote {
	padding: $padding-large-horizontal*1.5;
	text-align: center;
	.magazine-blockquote-con {
		margin-bottom: 50px;
    margin-bottom: 0;
		position: relative;
		&:before {
      content: '"';
      position: absolute;
      top: -81px;
      left: -16px;
      width: auto;
      height: auto;
      font-size: 250px;
      color: $brand-primary;
      z-index: 0;
    }
    &:after {
      content: '"';
      position: absolute;
      bottom: -211px;
      right: -17px;
      width: auto;
      height: auto;
      font-size: 250px;
      color: $brand-primary;
      z-index: 0;
    }
	}
	blockquote {
		border: none;
		position: relative;
    z-index: 20;
    font-size: $font-size-h1;
    line-height: 1.1;
    margin-bottom: 30px;
    margin-bottom: 0;
    color: $brand-primary;
    .mb-source {
      font-size: $font-size-large;
      display: block;
      margin-top: $padding-small-vertical;
      color: $text-color;
    }
	}

  &.magazine-blockquote__small {
    blockquote {
      font-size: $font-size-h3;
      .mb-source {
        font-size: $font-size-base;
      }
    }
  }
}

@media( min-width: $screen-md )
{
  .magazine-blockquote {
    blockquote {
      line-height: 1.2;
    }
  }
}